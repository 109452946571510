/*
* ----------------------------------------------------------------------------------------
Author       : Zakir Soft
Template Name: Coming Soon HTML Template
Version      : 1.0
* ----------------------------------------------------------------------------------------
*/
/*
* ----------------------------------------------------------------------------------------
* 01.Header CSS STYLE
* 02.News Letter CSS STYLE
* 03.NOnly Mobile View CSS STYLE
* ----------------------------------------------------------------------------------------
*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap");
.main {
	min-height: 100vh;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	background-image: url(bg.jpg);
}

header {
	padding-top: 30px;
}

header nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}

header .logo {
	width: 465px;
}

header .logo svg {
	max-width: 100%;
	height: auto;
	-o-object-fit: cover;
	object-fit: cover;
}

header .social-icon a {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 50px;
	width: 50px;
	border-radius: 50%;
	border: 1px solid #fff;
	color: #fff;
	font-size: 20px;
	text-decoration: none;
	-webkit-transition: 0.3s all linear;
	transition: 0.3s all linear;
}

header .social-icon a:hover {
	border-color: #ff3251;
	background-color: #ff3251;
}

.has-line {
	padding-left: 100px;
	position: relative;
}

.has-line::before {
	position: absolute;
	content: "";
	top: 50%;
	left: 0;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
	background-color: #333333;
	width: 88px;
	height: 2px;
}

.form-group {
	margin-top: 30px;
	position: relative;
}

.form-group input {
	width: 710px;
	height: 70px;
	border: 1px solid #b1b6c5;
	border-radius: 40px;
	padding-left: 30px;
	-webkit-transition: 0.3s all linear;
	transition: 0.3s all linear;
}

.form-group input:focus {
	border-color: #ff3251;
}

.form-group button {
	width: 175px;
	height: 70px;
	border: 1px solid #ff3251;
	border-radius: 40px;
	background-color: #ff3251;
	color: #fff;
	font-size: 20px;
	font-weight: 500;
	position: absolute;
	top: 0;
	right: 145px;
}

.newsletter-area {
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: center;
}

.screen-shots {
	width: 40%;
}

@media (max-width: 991px) {
	.screen-shots {
		width: 50%;
	}
}

@media (max-width: 420px) {
	.main {
		background-image: none !important;
		display: block !important;
	}
	header {
		padding-top: 15px !important;
	}
	.form-group input {
		width: 100%;
		height: 50px;
	}
	.form-group button {
		height: 50px;
		width: 130px;
		right: 0;
	}
	.has-line {
		text-align: center;
		padding: 0;
	}
	.has-line::before {
		display: none;
	}
	h1 {
		text-align: center;
	}
	.social-icon a {
		height: 35px !important;
		width: 35px !important;
		color: #001141 !important;
		border-color: #001141 !important;
	}
	header .logo {
		width: 140px;
	}
	.newsletter-area {
		padding-top: 150px;
		margin-bottom: 50px;
	}
}

@media only screen and (min-width: 420px) and (max-width: 574px) {
	.main {
		background-image: none !important;
		display: block !important;
	}
	header {
		padding-top: 15px !important;
	}
	.form-group input {
		width: 100%;
		height: 50px;
	}
	.form-group button {
		height: 50px;
		width: 130px;
		right: 0;
	}
	.has-line {
		text-align: center;
		padding: 0;
	}
	.has-line::before {
		display: none;
	}
	h1 {
		text-align: center;
	}
	.social-icon a {
		height: 35px !important;
		width: 35px !important;
		color: #001141 !important;
		border-color: #001141 !important;
	}
	header .logo {
		width: 150px;
	}
	.newsletter-area {
		padding-top: 150px;
		margin-bottom: 50px;
	}
}

@media (min-width: 575px) and (max-width: 767px) {
	.main {
		background-image: none !important;
		display: block !important;
	}
	header {
		padding-top: 15px !important;
	}
	.form-group input {
		width: 100%;
		height: 50px;
	}
	.form-group button {
		height: 50px;
		width: 130px;
		right: 0;
	}
	.has-line {
		text-align: center;
		padding: 0;
	}
	.has-line::before {
		display: none;
	}
	h1 {
		text-align: center;
	}
	.social-icon a {
		height: 35px !important;
		width: 35px !important;
		border-color: #001141 !important;
		color: #001141 !important;
	}
	header .logo {
		width: 160px;
	}
	.newsletter-area {
		padding-top: 150px;
		margin-bottom: 50px;
	}
}

@media (min-width: 768px) and (max-width: 991px) {
	.main {
		background-image: none !important;
	}
	.social-icon a {
		color: #001141 !important;
		border-color: #001141 !important;
	}
	.form-group input {
		width: 100%;
		height: 55px;
	}
	.form-group button {
		height: 55px;
		right: 0;
	}
	.has-line {
		text-align: center;
		padding: 0;
	}
	.has-line::before {
		display: none;
	}
	h5 {
		font-size: 18px !important;
	}
	h1 {
		margin: 0 auto;
		max-width: 540px !important;
		text-align: center;
		font-size: 60px !important;
		line-height: 68px !important;
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	.form-group button {
		right: 0 !important;
		height: 60px;
	}
	.form-group input {
		width: 90%;
		height: 60px;
	}
}

@media (min-width: 1201px) and (max-width: 1399px) {
	.form-group button {
		right: 0 !important;
	}
	.form-group input {
		width: 90%;
	}
}

body {
	font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
}

h1,
h5 {
	color: #001141;
}

h1 {
	font-size: 94px;
	line-height: 102px;
	font-weight: 700;
	max-width: 820px;
}

h5 {
	font-size: 22px;
	font-weight: 500;
}

@media (max-width: 767px) {
	h1 {
		font-size: 32px;
		line-height: 40px;
	}
	h5 {
		font-size: 16px;
	}
}

@media (min-width: 992px) and (max-width: 1200px) {
	h1 {
		font-size: 46px;
		line-height: 44px;
	}
	h5 {
		font-size: 20px;
	}
}

* {
	margin: 0;
	padding: 0;
	outline: 0;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

html {
	scroll-behavior: smooth;
}

img {
	border: 0;
	outline: 0;
	max-width: 100%;
	vertical-align: middle;
}

body {
	position: relative;
}
/*# sourceMappingURL=main.css.map */
